import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { HomeIcon } from "@heroicons/react/24/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BACKEND_URL } from "../../Default";

const RequiredService = () => {
    const { requestId } = useParams();
    const navigate = useNavigate();
    const [requiredServices, setRequiredServices] = useState({});
    const [aircraftNature, setAircraftNature] = useState('');
    const [flightType, setFlightType] = useState('');
    const [subRequestName, setSubRequestName] = useState('');
    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [user, setUser] = useState({});
    const [submitLoading, setSubmitLoading] = useState(false);
    const [dataRetrieved, setDataRetrieved] = useState(true);
    const [aocFile, setAocFile] = useState(null);

    useEffect(() => {
        const user = localStorage.getItem('user');
        const parsedUser = JSON.parse(user);
        setUser(parsedUser);

        const fetchCategories = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/requests/request-categories-with-sub`);
                const data = await response.data;
                setCategories(data);
                setCategoriesLoading(false);
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        };



        fetchCategories();

        if (requestId) {
            const fetchExistingData = async () => {
                try {
                    const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${requestId}`);
                    const data = response.data;

                    // Set form state with fetched data
                    setAircraftNature(data.request_category.name);
                    setFlightType(data.flightType);
                    setSubRequestName(data.sub_request.name);

                    if (data && (data.passengers.length > 0 || data.cargos.length > 0)) {
                        setRequiredServices(data);
                        setDataRetrieved(false);
                    } else {
                        setRequiredServices(data);
                        setDataRetrieved(true);
                    }
                    // Mark that data has been retrieved
                } catch (error) {
                    console.error('Error fetching existing data:', error);
                }
            };

            fetchExistingData();
        }
    }, [requestId]);

    const handleAircraftNatureChange = (event) => {
        const selectedNature = event.target.value;
        setAircraftNature(selectedNature);

        const selectedCategory = categories.find(category => category.name === selectedNature);

        if (selectedCategory && selectedCategory.sub_categories.length === 1) {
            setFlightType(selectedCategory.sub_categories[0].flightType);
            setSubRequestName(selectedCategory.sub_categories[0].sub_request_name);
        } else {
            setFlightType('');
            setSubRequestName('');
        }
    };

    const handleFlightTypeChange = (event) => {
        const selectedSubCategory = event.target.value;
        const selectedCategory = categories.find(category => category.name === aircraftNature);

        if (selectedCategory) {
            const subCategory = selectedCategory.sub_categories.find(sub => sub.sub_request_name === selectedSubCategory);
            setFlightType(subCategory.flightType);
            setSubRequestName(subCategory.sub_request_name);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (submitLoading) return; // Prevent multiple submissions
        setSubmitLoading(true);
        if(dataRetrieved) {
            try {
                // Get IDs of the selected category and sub-category
                const selectedCategory = categories.find(category => category.name === aircraftNature);
                const selectedSubCategory = selectedCategory?.sub_categories.find(sub => sub.sub_request_name === subRequestName);

                if (!selectedCategory || !selectedSubCategory) {
                    console.error('Category or Sub-category not selected');
                    alert('Please select a valid category and sub-category.');
                    setSubmitLoading(false);
                    return;
                }

                const updatedServices = {
                    ...requiredServices,
                    request_category_id: selectedCategory.category_id,
                    sub_category: selectedSubCategory.sub_category_id,
                };

                const formData = new FormData();
                const user = JSON.parse(localStorage.getItem('user'));
                if (!user || !user.id) {
                    throw new Error('User not found in local storage');
                }

                formData.append('client_id', user.id);
                if (requestId) {
                    formData.append('request_id', requestId);
                }
                formData.append('request_data', JSON.stringify(updatedServices));
                formData.append('status', 'incomplete');

                const response = await axios.post(`${BACKEND_URL}/permit/request-type`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                console.log('Response from backend:', response.data);

                // Save user selection to localStorage
                localStorage.setItem('purpose', JSON.stringify(selectedCategory.name));
                localStorage.setItem('type', JSON.stringify(selectedSubCategory.flightType));
                localStorage.setItem('subPurpose', JSON.stringify(selectedSubCategory.sub_request_name));

                const insertId = response.data.data.insertId;


                        if (user.company_type === "OPERATOR"){
                            const updatedServices = {
                                ...requiredServices,
                                operator: {
                                    name: user.company_name,
                                    address: user.company_address,
                                    email: user.email,
                                    countryCode: user.country_code,
                                    contactNumber: user.contact_no,
                                },
                            };

                            const formData = new FormData();
                            formData.append('client_id', JSON.parse(localStorage.getItem('user')).id);
                            formData.append('request_id', insertId);
                            formData.append('request_data', JSON.stringify(updatedServices));
                            formData.append('status', 'incomplete');

                            if (aocFile && !aocFile.path) {
                                formData.append('files', aocFile, `AOC.${aocFile.type.split('/')[1]}`);
                            }

                            localStorage.setItem('requiredServices', JSON.stringify(updatedServices));

                            try {
                                const response = await axios.post(`${BACKEND_URL}/permit/operator-details`, formData, {
                                    headers: {
                                        'Content-Type': 'multipart/form-data'
                                    }
                                });
                                console.log('Response from backend:', response.data);
                                setSubmitLoading(false);
                                navigate(`/permit/new/permit-type/${insertId}`);
                            } catch (error) {
                                console.error('Error sending data to backend:', error);
                                setSubmitLoading(false);
                            }

                        } else {
                            navigate(`/permit/new/flight-details/${insertId}`);
                        }

            } catch (error) {
                console.error('Error sending data to backend:', error);
                alert(`Failed to submit: ${error.message}`);
            } finally {
                setSubmitLoading(false);
            }

        }
    };



    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/dashboard' },
        { label: 'Permit', url: '' },
        { label: 'New', url: '/permit/new' },
    ];

    return (
        <div className="w-full min-w-full px-4">
            <main>
                <div className="mt-3">
                    <Breadcrumb items={breadcrumbItems} />
                </div>
                <div className="mt-3">
                    <h1 className="font-bold text-gray-800 text-xl">Purpose of Flight</h1>
                </div>
                <div className="mt-3">
                    <form onSubmit={handleSubmit}>
                        <div className="bg-white rounded-md py-4 px-6 shadow-md">
                            <h2 className="text-lg font-semibold mb-4">Choose your purpose of flight</h2>
                            <div>
                                {categoriesLoading ? (
                                    <div className="flex justify-center items-center p-5">
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                                        {categories.map(category => (
                                            <div key={category.category_id} className="p-4 border rounded-lg shadow-sm hover:shadow-md transition-shadow">
                                                <input
                                                    type="radio"
                                                    id={category.name.toLowerCase().replace(/\s+/g, '-')}
                                                    name="aircraftNature"
                                                    value={category.name}
                                                    checked={aircraftNature === category.name}
                                                    onChange={handleAircraftNatureChange}
                                                    className="hidden"
                                                />
                                                <label
                                                    htmlFor={category.name.toLowerCase().replace(/\s+/g, '-')}
                                                    className={`block cursor-pointer p-3 rounded-md ${aircraftNature === category.name ? 'bg-fts-gold text-fts-blue' : 'bg-white text-gray-800'}`}
                                                >
                                                    <span className="font-bold text-lg">{category.name}</span>
                                                    {aircraftNature === category.name && category.sub_categories.length > 1 && (
                                                        <div className="mt-3">
                                                            {category.sub_categories.map(subCategory => (
                                                                <div key={subCategory.sub_category_id} className="mt-3">
                                                                    <input
                                                                        type="radio"
                                                                        id={`${category.name.toLowerCase().replace(/\s+/g, '-')}-${subCategory.sub_request_name.toLowerCase().replace(/\s+/g, '-')}`}
                                                                        name="subCategory"
                                                                        value={subCategory.sub_request_name}
                                                                        checked={subRequestName === subCategory.sub_request_name}
                                                                        onChange={handleFlightTypeChange}
                                                                        className="hidden"
                                                                    />
                                                                    <label
                                                                        htmlFor={`${category.name.toLowerCase().replace(/\s+/g, '-')}-${subCategory.sub_request_name.toLowerCase().replace(/\s+/g, '-')}`}
                                                                        className={`block cursor-pointer p-2 rounded-md ${subRequestName === subCategory.sub_request_name ? 'bg-fts-blue text-fts-gold' : 'bg-white text-gray-800'}`}
                                                                    >
                                                                        {subCategory.sub_request_name}
                                                                    </label>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>

                            <div className="flex justify-end items-end mt-6">
                                {submitLoading ? (
                                    <button type="button" className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                        <CircularProgress size={20} />
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        // Disable button if data is already retrieved
                                    >
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                    </button>
                                )}
                            </div>

                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default RequiredService;
