import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { HomeIcon } from "@heroicons/react/16/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {
    Card,
    CardContent,
    CircularProgress,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button
} from '@mui/material';
import { BACK } from "../assets/wordings/constants";
import axios from "axios";
import { BACKEND_URL } from "../Default";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getStorageItem} from "../utils/storage";

function RequestSummary() {
    const { uniqueId } = useParams();
    const navigate = useNavigate();
    const [permitData, setPermitData] = useState(null);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [sessionType, setSessionType] = useState(null);

    useEffect(() => {
        const data = getStorageItem('generalInformation');
        if (data) {
            setPermitData(data);
            setSessionType(parseInt(data.permitType, 10));
        } else {
            console.warn('No data found in localStorage.');
            // navigate(`/guest/terms-and-conditions/${uniqueId}`);
        }
    }, [navigate, uniqueId]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);
        if (!permitData) {
            toast.error('No data found to submit.');
            setSubmitLoading(false);
            return;
        }

        try {
            const formData = new FormData();
            formData.append('request_id', uniqueId);
            formData.append('operator_name', permitData.company_name);
            formData.append('guest_name', permitData.guest_name);
            formData.append('email', permitData.guest_email);
            formData.append('contact', `${permitData.country_code}${permitData.company_contact_number}`);
            formData.append('request_data', JSON.stringify(permitData));
            formData.append('request_status', 'submitted');

            const response = await axios.post(`${BACKEND_URL}/guests/new`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            toast.success(response.data.message, {
                onClose: () => {
                    setSubmitLoading(false);
                    localStorage.clear();
                    navigate('/');
                }
            });
        } catch (error) {
            console.error('Error sending data to backend:', error);
            toast.error('Failed to submit the request. Please try again.');
            setSubmitLoading(false);
        }
    };


    // Handle back navigation
    const goBack = () => {
        navigate(`/guest/terms-and-conditions/${uniqueId}`);
    };

    // Render landing details table
    const renderLandingDetails = () => (
        <TableContainer component={Paper} className="mb-4">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Departure Airport</strong></TableCell>
                        <TableCell><strong>Departure Date</strong></TableCell>
                        <TableCell><strong>Departure Time</strong></TableCell>
                        <TableCell><strong>Arrival Airport</strong></TableCell>
                        <TableCell><strong>Arrival Date</strong></TableCell>
                        <TableCell><strong>Arrival Time</strong></TableCell>
                        <TableCell><strong>Landing</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {permitData.landing?.flightSchedule.map((landing, index) => (
                        <TableRow key={index}>
                            <TableCell>{landing.depAirport.label} </TableCell>
                            <TableCell>{new Date(landing.depDate).toLocaleDateString()}</TableCell>
                            <TableCell>{landing.depTime}</TableCell>
                            <TableCell>{landing.arrAirport.label} </TableCell>
                            <TableCell>{new Date(landing.arrDate).toLocaleDateString()}</TableCell>
                            <TableCell>{landing.arrTime}</TableCell>
                            <TableCell>
                                {landing.landingRequired ? 'Yes' : 'No'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    // Render overflying details table
    const renderOverflyingDetails = () => (
        <TableContainer component={Paper} className="mb-4">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Country</strong></TableCell>
                        <TableCell><strong>Entry Airspace</strong></TableCell>
                        <TableCell><strong>Entry Date</strong></TableCell>
                        <TableCell><strong>Entry Time</strong></TableCell>
                        <TableCell><strong>Exit Airspace</strong></TableCell>
                        <TableCell><strong>Exit Date</strong></TableCell>
                        <TableCell><strong>Exit Time</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {permitData.overflying?.airspacePoints.map((overflying, index) => (
                        <TableRow key={index}>
                            <TableCell>{overflying.country.label}</TableCell>
                            <TableCell>{overflying.entryPoint}</TableCell>
                            <TableCell>{new Date(overflying.entryDate).toLocaleDateString()}</TableCell>
                            <TableCell>{overflying.entryTime}</TableCell>
                            <TableCell>{overflying.exitPoint}</TableCell>
                            <TableCell>{new Date(overflying.exitDate).toLocaleDateString()}</TableCell>
                            <TableCell>{overflying.exitTime}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    // Render aircraft details
    const renderAircraftDetails = () => (
        <div className="mb-4 grid grid-cols-1 gap-4">
            <div>
                <Table>
                    <TableCell>
                        <Typography variant="body1" className="mb-2"><strong>Registration:</strong> {permitData?.aircraftDetails.aircraftPrefix || '-'} {permitData?.aircraftDetails.aircraftRegNo || '-'} </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body1" className="mb-2"><strong>Model:</strong> {permitData?.aircraftDetails.selectedType || '-'}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body1" className="mb-2"><strong>MTOW:</strong> {permitData?.aircraftDetails.aircraftMTOW || '-'} Kg</Typography>
                    </TableCell>
                </Table>
            </div>
        </div>
    );

    if (!permitData) {
        return <CircularProgress />;
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="flex flex-col h-full">
                <main className="flex-grow">
                    <div className="w-full min-w-full px-4">
                        <div className="mt-3 justify-center text-center">
                            <h1 className="font-bold text-fts-gold text-xl">Request Overview - Ref No {uniqueId}</h1>
                        </div>
                        <div className="mt-6">
                            <form onSubmit={handleSubmit} className="text-gray-800">
                                <Card className="mb-6">
                                    <CardContent>
                                        <Typography variant="h4" className="font-bold mb-4">Request Details</Typography>
                                        <div className=" mb-4">
                                            <div className="grid grid-cols-3 gap-1">
                                                <Typography variant="body1" className="mb-1"><strong>Service Type:</strong> {permitData.aircraftNature || '-'}</Typography>
                                                <Typography variant="body1" className="mb-1"><strong>Purpose:</strong> {permitData.subRequestName || '-'}</Typography>
                                                <Typography variant="body1" className="mb-1 capitalize"><strong>Flight Type:</strong> {permitData.flightType || '-'}</Typography>
                                            </div>
                                        </div>

                                        <Typography variant="h6" className="font-bold  mb-4">Guest Details</Typography>
                                        <div className="grid grid-cols-2 gap-2">
                                            <Typography variant="body1" className="mb-2"><strong>Name:</strong> {permitData.guest_name || '-'}</Typography>
                                            <Typography variant="body1" className="mb-2"><strong>Email:</strong> {permitData.guest_email || '-'}</Typography>
                                        </div>

                                        <div className="mt-4">
                                            <Typography variant="h6" className="font-bold  mb-4">Operator Details</Typography>
                                            <div className="grid grid-cols-2 gap-2 mb-4">
                                                <Typography variant="body1" className="mb-2"><strong>Name:</strong> {permitData.company_name || '-'}</Typography>
                                                <Typography variant="body1" className="mb-2"><strong>Address:</strong> {permitData.company_address || '-'}</Typography>
                                                <Typography variant="body1" className="mb-2"><strong>Email:</strong> {permitData.company_email || '-'}</Typography>
                                                <Typography variant="body1" className="mb-2"><strong>Contact Number:</strong> {`${permitData.country_code || '-'} ${permitData.company_contact_number || '-'}`}</Typography>
                                            </div>
                                        </div>

                                        {permitData.landing?.flightSchedule?.length > 0 && (
                                            <>
                                                <Typography variant="h6" className="font-bold mb-4 mt-4">Schedule</Typography>
                                                {renderLandingDetails()}
                                            </>
                                        )}

                                        {(sessionType === 2 || sessionType === 4) && (
                                            <>
                                                <Typography variant="h6" className="font-bold mb-4 mt-2">Overflying Details</Typography>
                                                {renderOverflyingDetails()}
                                            </>
                                        )}

                                        <Typography variant="h6" className="font-bold mb-4">Aircraft Details</Typography>
                                        {renderAircraftDetails()}
                                    </CardContent>
                                </Card>

                                <div className="flex justify-between mt-4">
                                    <button
                                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        type="button"
                                        onClick={goBack}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                        {BACK}
                                    </button>

                                    {submitLoading ? (
                                        <button
                                            type="button"
                                            className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        >
                                            <CircularProgress size={20}/>
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        >
                                            Submit
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
}

export default RequestSummary;
