import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { BACKEND_URL } from "../Default";

const DgrDocumentUpload = () => {
    const { uniqueId } = useParams();
    const navigate = useNavigate();
    const [sessionType, setSessionType] = useState(null);
    const [type, setType] = useState(null);
    const [operatorData, setOperatorData] = useState({});
    const [dGCertificate, setDGCertificate] = useState([]);
    const [personDG, setPersonDG] = useState([]);
    const [OPS, setOPS] = useState([]);
    const [insurance, setInsurance] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {

        fetchExistingData();
    }, [navigate]);


    const fetchExistingData = () => {
        try {
            const response = localStorage.getItem('generalInformation');
            if (response) {
                const existingData = JSON.parse(response);
                console.log('Fetched data from localStorage:', existingData);

                // Ensure dgrDocuments is initialized to prevent errors
                if (!existingData.dgrDocuments) {
                    existingData.dgrDocuments = {
                        dGCertificate: [],
                        personDG: [],
                        OPS: [],
                        insurance: []
                    };
                }

                setOperatorData(existingData);
                setSessionType(parseInt(existingData.permitType, 10));
                setType(existingData.flightType || '');

                setDGCertificate(existingData.dgrDocuments.dGCertificate || []);
                setPersonDG(existingData.dgrDocuments.personDG || []);
                setOPS(existingData.dgrDocuments.OPS || []);
                setInsurance(existingData.dgrDocuments.insurance || []);
            } else {
                console.log('No existing data found in localStorage.');
            }
        } catch (error) {
            console.error('Error fetching existing data:', error);
        }
    };





    const handleFileChange = (setFileState) => (event) => {
        setFileState(Array.from(event.target.files));
    };

    const saveToLocalStorage = () => {
        const updatedData = {
            ...operatorData,
            dgrDocuments: {
                dGCertificate: dGCertificate || [],
                personDG: personDG || [],
                OPS: OPS || [],
                insurance: insurance || []
            },
        };
        localStorage.setItem('generalInformation', JSON.stringify(updatedData));
        console.log("Data saved to localStorage:", JSON.parse(localStorage.getItem('generalInformation')));
    };

// Call saveToLocalStorage before navigating away
    const goBack = () => {
        saveToLocalStorage(); // Save data before navigating away
        if (type && type.toLowerCase() === "passenger") {
            navigate(`/guest/passenger-documents-upload/${uniqueId}`);
        } else if (type && type.toLowerCase() === "cargo") {
            navigate(`/guest/cargo-documents-upload/${uniqueId}`);
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        setSubmitLoading(true);
        saveToLocalStorage()

        // Form data submission
        const formData = new FormData();
        formData.append('request_id', uniqueId);
        formData.append('operator_name', operatorData.company_name);
        formData.append('guest_name', operatorData.guest_name);
        formData.append('email', operatorData.guest_email);
        formData.append('contact', `${operatorData.country_code}${operatorData.company_contact_number}`);
        formData.append('request_data', JSON.stringify(operatorData));
        formData.append('request_status', 'incomplete');

        // Function to generate short keys for file naming
        const generateShortKey = () => Math.random().toString(36).substr(2, 5);

        // Append files to formData with unique keys
        dGCertificate.forEach((file) => {
            formData.append('files', file, `DG-Certificate-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        personDG.forEach((file) => {
            formData.append('files', file, `Person-DG-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        OPS.forEach((file) => {
            formData.append('files', file, `OPS-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        insurance.forEach((file) => {
            formData.append('files', file, `Insurance-${generateShortKey()}.${file.type.split('/')[1]}`);
        });

        try {
            const response = await axios.post(`${BACKEND_URL}/guests/new`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log('Form submission response:', response.data);
            setSubmitLoading(false);
            navigate(`/guest/terms-and-conditions/${uniqueId}`);
        } catch (error) {
            console.error('Error during form submission:', error);
            setSubmitLoading(false);
        }
    };

    return (
        <div className="w-full min-w-full min-h-[88vh] px-4">
            <main className="">
                <div className="mt-3 text-center max-w-4xl mx-auto">
                    <h1 className="font-bold text-[48px] text-fts-gold">DGR Documents</h1>
                    <hr className="w-[90%] border-fts-gold border-2 my-4"/>
                </div>
                <div className="mt-3 max-w-4xl mx-auto">
                    <form onSubmit={handleSubmit} className="text-gray-800">
                        <div className="text-start">
                            <div className="text-white py-2 px-4 rounded-md">
                                <h2>Upload the following fields</h2>
                                <div className="grid grid-cols-1 gap-4 mx-12 px-12 mt-3 justify-center items-center">
                                    <div className="mb-2 grid grid-cols-2 gap-2">
                                        <label>Dangerous goods certificate:</label>
                                        <div>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleFileChange(setDGCertificate)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-2 grid grid-cols-2 gap-2">
                                        <label>DG Certificate of person signed DG:</label>
                                        <div>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleFileChange(setPersonDG)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-2 grid grid-cols-2 gap-2">
                                        <label>OPS Specification:</label>
                                        <div>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleFileChange(setOPS)}
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-2 grid grid-cols-2 gap-2">
                                        <label>Insurance Certificate:</label>
                                        <div>
                                            <input
                                                type="file"
                                                multiple
                                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                onChange={handleFileChange(setInsurance)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between mt-4">
                                <button
                                    className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                    type="button"
                                    onClick={goBack}
                                >
                                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                    Back
                                </button>
                                {submitLoading ? (
                                    <button type="button"
                                            className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                        <CircularProgress size={20}/>
                                    </button>
                                ) : (
                                    <button type="submit"
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                        Next
                                        <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                    </button>
                                )}
                            </div>
                        </div>
                    </form>
                </div>
            </main>
        </div>
    );
};

export default DgrDocumentUpload;
