import React, { useState, useEffect } from 'react';
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../assets/components/breadcrumb";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { BACK } from "../assets/wordings/constants";
import axios from 'axios';
import { CircularProgress } from "@mui/material";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import { v4 as uuidv4 } from 'uuid';
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";

const generateUniqueId = () => {
    // Generate a UUID v4
    const uuid = uuidv4();
    // Extract the first 6 digits from the UUID to use as the unique part
    const uniquePart = uuid.split('-')[0].slice(0, 6).toUpperCase();
    return `FTS-${uniquePart}`;
};

const GeneralInformationForm = () => {
    const navigate = useNavigate();
    const [guestName, setGuestName] = useState('');
    const [guestEmail, setGuestEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyEmail, setCompanyEmail] = useState('');
    const [companyContactNumber, setCompanyContactNumber] = useState('');
    const [countryCode, setCountryCode] = useState('');
    const [submitLoading, setSubmitLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [countryCodes, setCountryCodes] = useState([]);
    const [captchaToken, setCaptchaToken] = useState('');
    const [uniqueId, setUniqueId] = useState('');

    useEffect(() => {

        const fetchExistingData = () => {
            try {
                // localStorage.clear();
                const id = generateUniqueId();
                setUniqueId(id);
                const response = localStorage.getItem('generalInformation');
                if (response) {
                    const existingData = JSON.parse(response);
                    setGuestName(existingData.guest_name || '');
                    setGuestEmail(existingData.guest_email || '');
                    setCompanyName(existingData.company_name || '');
                    setCompanyAddress(existingData.company_address || '');
                    setCompanyEmail(existingData.company_email || '');
                    setCompanyContactNumber(existingData.company_contact_number || '');
                    setCountryCode(existingData.country_code || '');
                }
            } catch (error) {
                console.error('Error fetching existing data:', error);
            }
        };

        const fetchCountryCodes = async () => {
            try {
                const response = await axios.get('https://restcountries.com/v3.1/all');
                const countries = response.data;
                const codes = countries.map(country => ({
                    country: country.name.common,
                    code: country.idd.root + (country.idd.suffixes ? country.idd.suffixes[0] : '')
                }));
                setCountryCodes(codes);
                setCountryCode(codes[0]?.code || ''); // Set default country code if no existing data
            } catch (error) {
                console.error('Error fetching country codes:', error);
            }
        };

        fetchCountryCodes();
        fetchExistingData();
    }, []);

    const handleRecaptcha = (token) => {
        setCaptchaToken(token);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        // if (!captchaToken) {
        //     setErrors(prevErrors => ({ ...prevErrors, recaptcha: 'Please complete the reCAPTCHA.' }));
        //     return;
        // }

        setSubmitLoading(true);

        const formData = {
            guest_name: guestName,
            guest_email: guestEmail,
            company_name: companyName,
            company_address: companyAddress,
            company_email: companyEmail,
            country_code: countryCode,
            company_contact_number: companyContactNumber,
        };

        localStorage.setItem('generalInformation', JSON.stringify(formData));
        localStorage.setItem('captchaToken', captchaToken);

        console.log('Form data stored in local storage:', formData);
        setSubmitLoading(false);
        navigate(`/guest/flight-purpose/${uniqueId}`);
    };

    const validate = () => {
        const errors = {};
        if (!guestName) errors.guestName = 'Guest name is required.';
        if (!guestEmail) errors.guestEmail = 'Guest email is required.';
        if (!companyName) errors.companyName = 'Company name is required.';
        if (!companyEmail) errors.companyEmail = 'Company email is required.';
        if (!companyAddress) errors.companyAddress = 'Company address is required.';
        // if (!captchaToken) errors.recaptcha = 'Please complete the reCAPTCHA.';
        return errors;
    };

    const goBack = () => {
        navigate(`/`);
    };

    const codeOptions = countryCodes.map((code) => ({
        value: code.code,
        label: `${code.code} (${code.country})`,
    }));

// Sort the codeOptions array based on the 'value' property
    const sortedCodeOptions = codeOptions.sort((a, b) => {
        // Compare the values numerically
        return parseInt(a.value, 10) - parseInt(b.value, 10);
    });


    return (
        <div className="flex flex-col">
            <main className="flex-grow">
                <div className="w-full min-w-full px-4">
                    <div className="mt-3">
                        <form onSubmit={handleSubmit}>
                            <div className="text-white py-2 px-4 rounded-md max-w-4xl">
                                <div className="mt-3">
                                    <h1 className="font-bold text-fts-gold text-2xl">General Information</h1>
                                </div>
                                <h2>Fill in the following fields</h2>

                                <div className="grid grid-cols-2 gap-5 mt-3">
                                    <div>
                                        <label htmlFor="guestName"
                                               className="text-sm text-gray-100 mb-2 inline-block">
                                            Guest Name
                                        </label>
                                        <input
                                            type="text"
                                            id="guestName"
                                            placeholder="Eg: John Doe"
                                            className="w-full text-black px-3 py-2 text-sm border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                            value={guestName}
                                            onChange={(e) => setGuestName(e.target.value)}
                                        />
                                        {errors.guestName && (
                                            <span className="text-red-500 text-xs">{errors.guestName}</span>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="guestEmail"
                                               className="text-sm text-gray-100 mb-2 inline-block">
                                            Guest Email
                                        </label>
                                        <input
                                            type="email"
                                            id="guestEmail"
                                            placeholder="Eg: john@ftsaero.com"
                                            className="w-full text-black px-3 py-2 text-sm border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                            value={guestEmail}
                                            onChange={(e) => setGuestEmail(e.target.value)}
                                        />
                                        {errors.guestEmail && (
                                            <span className="text-red-500 text-xs">{errors.guestEmail}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-5 mt-3">
                                    <div>
                                        <label htmlFor="companyName"
                                               className="text-sm text-gray-100 mb-2 inline-block">
                                            Operator Name
                                        </label>
                                        <input
                                            type="text"
                                            id="companyName"
                                            placeholder="Eg: FTS"
                                            className="w-full text-black px-3 py-2 text-sm border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                        />
                                        {errors.companyName && (
                                            <span className="text-red-500 text-xs">{errors.companyName}</span>
                                        )}
                                    </div>
                                    <div>
                                        <label htmlFor="companyEmail"
                                               className="text-sm text-gray-100 mb-2 inline-block">
                                            Operator Email
                                        </label>
                                        <input
                                            type="email"
                                            id="companyEmail"
                                            placeholder="Eg: john@ftsaero.com"
                                            className="w-full text-black px-3 py-2 text-sm border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                            value={companyEmail}
                                            onChange={(e) => setCompanyEmail(e.target.value)}
                                        />
                                        {errors.companyEmail && (
                                            <span className="text-red-500 text-xs">{errors.companyEmail}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="grid grid-cols-2 gap-5 mt-3">
                                    <div>
                                        <label htmlFor="companyContactNumber"
                                               className="text-sm text-gray-100 mb-2 inline-block">
                                            Operator Contact Number
                                        </label>
                                        <div className="flex">
                                            <Select
                                                className="w-1/3 text-sm"
                                                value={sortedCodeOptions.find(option => option.value === countryCode)}
                                                onChange={(selectedOption) => setCountryCode(selectedOption.value)}
                                                options={sortedCodeOptions}
                                                placeholder="Code"
                                                isSearchable
                                                menuPortalTarget={document.body}
                                                menuPosition="fixed"
                                                styles={{
                                                    menuPortal: base => ({...base, zIndex: 9999}),
                                                    control: (provided) => ({
                                                        ...provided,
                                                        border: '2px solid #D1D5DB',
                                                        borderRadius: '0.375rem 0 0 0.375rem',
                                                        padding: '0.625rem', // Increased padding for more space
                                                        fontSize: '1rem', // Adjusted font size for readability
                                                    }),
                                                    menu: (provided) => ({
                                                        ...provided,
                                                        width: 'max-content', // Ensures the dropdown menu expands to fit content
                                                        minWidth: '100%', // Keeps the menu at least as wide as the select box
                                                    }),
                                                    singleValue: (provided) => ({
                                                        ...provided,
                                                        marginLeft: '10px',
                                                    }),
                                                    placeholder: (provided) => ({
                                                        ...provided,
                                                        marginLeft: '10px',
                                                    }),
                                                    indicatorSeparator: (provided) => ({
                                                        ...provided,
                                                        display: 'none', // Removes the vertical line between select box and dropdown arrow
                                                    }),
                                                    dropdownIndicator: (provided) => ({
                                                        ...provided,
                                                        padding: '0.25rem', // More space around the dropdown arrow
                                                    }),
                                                }}
                                            />

                                            <input
                                                type="text"
                                                id="companyContactNumber"
                                                className="w-2/3 px-3 text-black py-2 text-sm border-2 border-gray-300 rounded-r-md focus:outline-none focus:border-blue-500"
                                                value={companyContactNumber}
                                                onChange={(e) => setCompanyContactNumber(e.target.value)}
                                            />
                                        </div>

                                    </div>
                                    <div>
                                        <label htmlFor="companyAddress"
                                               className="text-sm text-gray-100 mb-2 inline-block">
                                            Operator Address
                                        </label>
                                        <input
                                            type="text"
                                            id="companyAddress"
                                            placeholder="Eg: No, Street Name, City, Country, Postal Code"
                                            className="w-full text-black px-3 py-2 text-sm border-2 border-gray-300 rounded-md focus:outline-none focus:border-blue-500"
                                            value={companyAddress}
                                            onChange={(e) => setCompanyAddress(e.target.value)}
                                        />
                                        {errors.companyAddress && (
                                            <span className="text-red-500 text-xs">{errors.companyAddress}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="mt-3">
                                    <ReCAPTCHA
                                        sitekey="6Le9US8qAAAAAFHJoNPtMAGf6pz6EfaX8YYg5I_2"
                                        onChange={handleRecaptcha}
                                    />
                                    {errors.recaptcha && (
                                        <span className="text-red-500 text-xs">{errors.recaptcha}</span>
                                    )}
                                </div>

                                <div className="flex justify-between mt-4 mx-auto">
                                    <button
                                        className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        type="button"
                                        onClick={goBack}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                        {BACK}
                                    </button>

                                    {submitLoading ? (
                                        <button
                                            type="button"
                                            className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        >
                                            <CircularProgress size={20}/>
                                        </button>
                                    ) : (
                                        <button
                                            type="submit"
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                        >
                                            Next
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default GeneralInformationForm;
