import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { BACKEND_URL } from '../../Default';
import {
    Card,
    CardContent,
    Typography,
    CircularProgress,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination
} from '@mui/material';
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import Airports from "../../assets/data/Airports.json";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ToastContainer} from "react-toastify";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons";

const PermitDetails = () => {
    const { ref } = useParams();
    const [permitData, setPermitData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPermitDetails = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/permit/get/detailed/${ref}`);
                const data = response.data;

                const mappedLandings = data.landings.map(landing => {
                    const departureAirport = Airports.find(airport => airport.id === landing.departure_airport.airport_id);
                    const arrivalAirport = Airports.find(airport => airport.id === landing.arrival_airport.airport_id);
                    return {
                        ...landing,
                        departure_airport: departureAirport,
                        arrival_airport: arrivalAirport,
                    };
                });

                setPermitData({
                    ...data,
                    landings: mappedLandings,
                });

                setLoading(false);
            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchPermitDetails();
    }, [ref]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <div className="text-center text-red-500">Error: {error}</div>;
    }

    if (!permitData) {
        return <p className="text-center">Details not available</p>;
    }

    const renderLandingDetails = () => (
        <TableContainer component={Paper} className="mb-4">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Flight No</strong></TableCell>
                        <TableCell><strong>Departure Airport</strong></TableCell>
                        <TableCell><strong>Departure Date</strong></TableCell>
                        <TableCell><strong>Departure Time</strong></TableCell>
                        <TableCell><strong>Arrival Airport</strong></TableCell>
                        <TableCell><strong>Arrival Date</strong></TableCell>
                        <TableCell><strong>Arrival Time</strong></TableCell>
                        <TableCell><strong>Landing Permit Requested</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {permitData.landings.map((landing, index) => (
                        <TableRow key={index}>
                            <TableCell>{landing.flight_no}</TableCell>
                            <TableCell>{landing.departure_airport.airport_name} - {landing.departure_airport.icao_code}</TableCell>
                            <TableCell>{new Date(landing.departure_date).toLocaleDateString()}</TableCell>
                            <TableCell>{landing.departure_time.slice(0, 5)}</TableCell>
                            <TableCell>{landing.arrival_airport.airport_name} - {landing.arrival_airport.icao_code}</TableCell>
                            <TableCell>{new Date(landing.arrival_date).toLocaleDateString()}</TableCell>
                            <TableCell>{landing.arrival_time.slice(0, 5)}</TableCell>

                            <TableCell>
                                {landing.landing === '1' ? 'Yes' : landing.landing === '0' ? 'No' : '-'}
                            </TableCell>

                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderOverflyingDetails = () => (
        <TableContainer component={Paper} className="mb-4">
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell><strong>Flight No</strong></TableCell>
                        <TableCell><strong>Country</strong></TableCell>
                        <TableCell><strong>Entry Airspace</strong></TableCell>
                        <TableCell><strong>Entry Date</strong></TableCell>
                        <TableCell><strong>Entry Time</strong></TableCell>
                        <TableCell><strong>Exit Airspace</strong></TableCell>
                        <TableCell><strong>Exit Date</strong></TableCell>
                        <TableCell><strong>Exit Time</strong></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {permitData.overflyings.map((overflying, index) => (
                        <TableRow key={index}>
                            <TableCell>{overflying.flightNo}</TableCell>
                            <TableCell>{overflying.country.country_name}</TableCell>
                            <TableCell>{overflying.entry_airspace}</TableCell>
                            <TableCell>{overflying.entry_date.split('T')[0]}</TableCell>
                            <TableCell>{overflying.entry_time.split('T')[0]}</TableCell>
                            <TableCell>{overflying.exit_airspace}</TableCell>
                            <TableCell>{overflying.exit_date.split('T')[0]}</TableCell>
                            <TableCell>{overflying.exit_time.split('T')[0]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const renderAircraftDetails = (aircraft) => (
        <div className="mb-4 grid grid-cols-2 gap-4">
            <div>
                <Table>
                    <TableRow>
                        <TableCell variant="h6" className="font-bold "><strong>Selected Registration No:</strong></TableCell>
                        <TableCell>
                            <Typography variant="body1" className="mb-2"> {aircraft.aircraft_registration || '-'}</Typography>
                        </TableCell>
                    </TableRow>

                </Table>
            </div>

        </div>
    );

    const renderAddOnServices = (addOns) => {
        // Parse addOns if it's a string
        if (typeof addOns === 'string') {
            addOns = JSON.parse(addOns);
        }

        // Ensure it's an array before mapping
        if (Array.isArray(addOns)) {
            return (
                <div className="mb-4 grid grid-cols-4">
                    {addOns.map((service, index) => (
                        <div key={index} className="flex items-center space-x-2">
                            <FontAwesomeIcon icon={faCheckSquare} />
                            <p>{service}</p>
                        </div>
                    ))}
                </div>
            );
        } else {
            return <div>No add-on services selected</div>;
        }
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/dashboard' },
        { label: 'Permit', url: '' },
        { label: 'Management', url: '/permit/management' },
    ];

    const goBack = () => {
        navigate('/permit/management');
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="flex flex-col h-full">
                <main className="flex-grow">
                    <div className="w-full min-w-full px-4">
                        <div className="mt-3 flex">
                            <button
                                className="flex items-center justify-between mr-6"
                                onClick={goBack}
                            >
                                <ArrowUturnLeftIcon className="h-4 w-5 mr-1" />
                                Back
                            </button>
                            <Breadcrumb items={breadcrumbItems} />
                        </div>
                        <div className="mt-3 justify-center text-center">
                            <h1 className="font-bold text-gray-800 text-xl">
                                Request Overview - Ref No #{permitData.request_id}
                            </h1>
                        </div>
                        <div className="mt-6">
                                <Card className="mb-6">
                                    <CardContent>
                                        <div className="grid grid-cols-2">
                                            <div>
                                                <Typography variant="h6" className="font-bold mb-4">
                                                    Request Details
                                                </Typography>
                                                <Table className="mb-4">
                                                    <TableRow>
                                                        <TableCell><strong>Purpose of Flight</strong></TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                {permitData.request_category.name || '-'}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell><strong>Flight Category</strong></TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1">
                                                                {permitData.sub_request.name || '-'}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    {/*{type.toLowerCase() === "cargo" && (*/}
                                                    {/*    <TableRow>*/}
                                                    {/*        <TableCell><strong>DGR</strong></TableCell>*/}
                                                    {/*        <TableCell>*/}
                                                    {/*            <Typography variant="body1">*/}
                                                    {/*                {permitData.isDGR === 1 ? 'Yes' : 'No'}*/}
                                                    {/*            </Typography>*/}
                                                    {/*        </TableCell>*/}
                                                    {/*    </TableRow>*/}
                                                    {/*)}*/}
                                                </Table>
                                            </div>
                                            <div>
                                                <Typography variant="h6" className="font-bold mb-4">
                                                    Operator Details
                                                </Typography>
                                                <Table>
                                                    <TableRow>
                                                        <TableCell><strong>Name:</strong></TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1" className="mb-2">
                                                                {permitData.operator?.operator_name || '-'}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell><strong>Address:</strong></TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1" className="mb-2">
                                                                {permitData.operator?.operator_address || '-'}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell><strong>Email:</strong></TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1" className="mb-2">
                                                                {permitData.operator?.operator_email || '-'}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell><strong>Phone:</strong></TableCell>
                                                        <TableCell>
                                                            <Typography variant="body1" className="mb-2">
                                                                {`${permitData.operator?.operator_country_code || '-'} ${permitData.operator?.operator_contact_no || '-'}`}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </div>
                                        </div>
                                        <Typography variant="h6" className="font-bold mb-4">
                                            Add On Services
                                        </Typography>
                                        {renderAddOnServices(permitData.selected_services)}

                                        {permitData.alternate_aircrafts.length > 0 && (
                                            <>
                                                <Typography variant="h6" className="font-bold mb-4">
                                                    Selected Alternate Aircraft Details
                                                </Typography>
                                                {permitData.alternate_aircrafts.map((alt, index) =>
                                                    renderAircraftDetails(alt.aircraft)
                                                )}
                                            </>
                                        )}

                                        {permitData.landings.length > 0 && (
                                            <>
                                                <Typography variant="h6" className="font-bold mb-4 mt-2">
                                                    Schedule
                                                </Typography>
                                                {renderLandingDetails()}
                                            </>
                                        )}

                                        {permitData.overflyings.length > 0 && (
                                            <>
                                                <Typography variant="h6" className="font-bold mb-4 mt-2">
                                                    Overflying Details
                                                </Typography>
                                                {renderOverflyingDetails()}
                                            </>
                                        )}

                                        <Typography variant="h6" className="font-bold mb-4 mt-2">
                                            Other Required Information
                                        </Typography>

                                        {permitData.passengers.length > 0 ? (
                                            permitData.passengers.map((passenger, index) => (
                                                <div key={index}>
                                                    <Typography variant="h6" className="font-bold mb-4">
                                                        Passenger Details
                                                    </Typography>
                                                    <div className="flex items-center">
                                                        <Typography variant="body1" className="mb-2">
                                                            <strong>Passenger Document {index + 1}:</strong>
                                                            {passenger.document?.document_name || '-'}
                                                        </Typography>
                                                        <a
                                                            href={`${BACKEND_URL}/${passenger.document?.file_path}`}
                                                            target="_blank"
                                                            className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View
                                                        </a>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <Typography>No files attached</Typography>
                                        )}

                                        {permitData.cargos.length > 0 && (
                                            permitData.cargos.map((cargo, index) => (
                                                <div key={index}>
                                                    <Typography variant="h6" className="font-bold mb-4">
                                                        Cargo Details
                                                    </Typography>
                                                    <div className="flex items-center">
                                                        <Typography variant="body1" className="mb-2">
                                                            <strong>Cargo Document {index + 1}:</strong>
                                                            {cargo.document?.document_name || '-'}
                                                        </Typography>
                                                        <a
                                                            href={`${BACKEND_URL}/${cargo.document?.file_path}`}
                                                            target="_blank"
                                                            className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View
                                                        </a>
                                                    </div>
                                                </div>
                                            ))
                                        )}

                                        {permitData.dgrs.length > 0 && (
                                            <div>
                                                <Typography variant="h6" className="font-bold mb-4 mt-4">
                                                    DGR Details
                                                </Typography>
                                                {permitData.dgrs.map((dgr, index) => (
                                                    <div key={index} className="flex items-center">
                                                        <Typography variant="body1" className="mb-2">
                                                            <strong>DGR Document {index + 1}:</strong>
                                                            {dgr.document?.document_name || '-'}
                                                        </Typography>
                                                        <a
                                                            href={`${BACKEND_URL}/${dgr.document?.file_path}`}
                                                            target="_blank"
                                                            className="bg-fts-blue text-white py-2 px-4 rounded-2xl ml-3 hover:bg-fts-gold"
                                                            rel="noopener noreferrer"
                                                        >
                                                            View
                                                        </a>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </CardContent>
                                </Card>
                        </div>
                    </div>
                </main>
            </div>
        </>

    );
};

export default PermitDetails;
