import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import axios from 'axios';
import { BACKEND_URL } from "../Default";
import { BACK } from "../assets/wordings/constants";
import { CircularProgress } from "@mui/material";

const AircraftDetails = () => {
    const { uniqueId } = useParams();
    const navigate = useNavigate();
    const [operatorData, setOperatorData] = useState({});
    const [aircraftTypes, setAircraftTypes] = useState([]);
    const [selectedType, setSelectedType] = useState('');
    const [aircraftPrefix, setAircraftPrefix] = useState('');
    const [aircraftRegNo, setAircraftRegNo] = useState('');
    const [aircraftMTOW, setAircraftMTOW] = useState('');
    const [airdropCapability, setAirdropCapability] = useState('');
    const [seatingCapacity, setSeatingCapacity] = useState('');
    const [onboardCommunication, setOnboardCommunication] = useState('');
    const [noiseCertificate, setNoiseCertificate] = useState(null);
    const [aocCertificate, setAOCCertificate] = useState(null);
    const [nationality, setNationality] = useState('');
    const [countries, setCountries] = useState([]);
    const [prefixOptions, setPrefixOptions] = useState([]);
    const [customType, setCustomType] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);

    useEffect(() => {
        const fetchExistingData = () => {
            try {
                const response = localStorage.getItem('generalInformation');

                if (response) {
                    const existingData = JSON.parse(response);
                    const aircraftData = existingData.aircraftDetails || {};
                    console.log("Existing Data: ",existingData)
                    setOperatorData(existingData);
                    setSelectedType(aircraftData.selectedType || '');
                    setAircraftPrefix(aircraftData.aircraftPrefix || '');
                    setAircraftRegNo(aircraftData.aircraftRegNo || '');
                    setAircraftMTOW(aircraftData.aircraftMTOW || '');
                    setAirdropCapability(aircraftData.airdropCapability || '');
                    setSeatingCapacity(aircraftData.seatingCapacity || '');
                    setOnboardCommunication(aircraftData.onboardCommunication || '');
                    setNoiseCertificate(aircraftData.noiseCertificate || null);
                    setAOCCertificate(aircraftData.aocCertificate || null);
                    setNationality(aircraftData.nationality || '');
                    setCustomType(aircraftData.customType || '');
                }
            } catch (error) {
                console.error('Error fetching existing data:', error);
            }
        };

        const fetchAircraftTypes = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${BACKEND_URL}/aircrafts/all-types`);
                setAircraftTypes(response.data);
            } catch (error) {
                console.error('Error fetching aircraft types:', error);
            } finally {
                setLoading(false);
            }
        };

        const fetchCountries = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/requests/countries/all`);
                const countryOptions = response.data.map((country) => ({
                    value: country.id,
                    label: country.country_name,
                }));
                setCountries(countryOptions);
            } catch (error) {
                console.error("Error fetching countries:", error);
            }
        };

        const fetchPrefixes = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/aircrafts/all-reg`);
                setPrefixOptions(response.data);
            } catch (error) {
                console.error('Error fetching prefixes:', error);
            }
        };

        fetchAircraftTypes();
        fetchCountries();
        fetchPrefixes();
        fetchExistingData();
    }, [navigate]);

    const validate = () => {
        const errors = {};
        if (!aircraftRegNo) errors.aircraftRegNo = 'Aircraft registration number is required.';
        if (!selectedType) errors.selectedType = 'Aircraft type is required.';
        if (!aircraftMTOW) errors.aircraftMTOW = 'MTOW is required.';
        // if (!airdropCapability) errors.airdropCapability = 'Airdrop capability is required.';
        if (!seatingCapacity) errors.seatingCapacity = 'Seating capacity is required.';
        // if (!onboardCommunication) errors.onboardCommunication = 'Onboard communication system is required.';
        if (!nationality) errors.nationality = 'Nationality is required.';
        if (noiseCertificate && !validateFile(noiseCertificate)) {
            errors.noiseCertificate = 'Invalid file. Only PDF and image files up to 1.5MB are allowed.';
        }

        // if (aocCertificate && !validateFile(aocCertificate)) {
        //     errors.aocCertificate = 'Invalid file. Only PDF and image files up to 1.5MB are allowed.';
        // }
        return errors;
    };

    const validateFile = (file) => {
        const validTypes = ['application/pdf', 'image/jpeg', 'image/png'];
        const maxSize = 1.5 * 1024 * 1024; // 1.5MB
        return validTypes.includes(file.type) && file.size <= maxSize;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }


        const existingData = {
            ...operatorData,
            aircraftDetails: {
                aircraftPrefix,
                aircraftRegNo,
                selectedType,
                customType: selectedType === 'other' ? customType : null,
                aircraftMTOW,
                // airdropCapability,
                seatingCapacity,
                // onboardCommunication,
                nationality,
            },
        };

        const formData = new FormData();

        formData.append('request_id', uniqueId);
        formData.append('operator_name', existingData.company_name);
        formData.append('guest_name', existingData.guest_name);
        formData.append('email', existingData.guest_email);
        formData.append('contact', `${existingData.country_code}${existingData.company_contact_number}`);
        formData.append('request_data', JSON.stringify(existingData));
        formData.append('request_status', 'incomplete');

        if (noiseCertificate) {
            formData.append('files', noiseCertificate, `Noise Certificate.${noiseCertificate.type.split('/')[1]}`); // Append file with name Noise Certificate and file type
        }

        if (aocCertificate) {
            formData.append('files', aocCertificate, `AOC Certificate.${aocCertificate.type.split('/')[1]}`); // Append file with name Noise Certificate and file type
        }

        setSubmitLoading(true);


        try {

            const response = await axios.post(`${BACKEND_URL}/guests/new`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
            localStorage.setItem('generalInformation', JSON.stringify(existingData));
            navigate(`/guest/permit-type/${uniqueId}`);

        } catch (error) {
            console.error(error);
            setSubmitLoading(false);
        }


    };

    const handlePrefixChange = (e) => {
        const selectedPrefix = e.target.value;
        setAircraftPrefix(selectedPrefix);

        const selectedCountry = prefixOptions.find(prefix => prefix.prefix === selectedPrefix);
        if (selectedCountry) {
            setNationality(selectedCountry.country);
        } else {
            setNationality('');
        }
    };


    const goBack = () => {
        navigate(`/guest/flight-purpose/${uniqueId}`);
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="text-center max-w-4xl mx-auto">
                    <h1 className="font-bold text-[48px] text-fts-gold">Aircraft Details</h1>
                    <hr className="w-[90%] border-fts-gold border-2 my-4" />
                    <div className="justify-start text-start text-white px-12 grid grid-cols-2 gap-2">
                        <div className="mb-4">
                            <label className="block">Aircraft Registration Number:</label>
                            <div className="flex">
                                <select
                                    className="border border-gray-300 px-3 py-2 rounded-md w-1/4 mr-2 text-black"
                                    value={aircraftPrefix}
                                    onChange={handlePrefixChange}
                                >
                                    <option value="">Select Prefix</option>
                                    {prefixOptions.map((option) => (
                                        <option key={option.id} value={option.prefix}>
                                            {option.prefix}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    className="border border-gray-300 px-3 py-2 rounded-md w-3/4 text-black"
                                    value={aircraftRegNo}
                                    onChange={(e) => setAircraftRegNo(e.target.value)}
                                    placeholder="Registration Number"
                                />
                            </div>
                            {errors.aircraftRegNo && <p className="text-red-500 text-sm">{errors.aircraftRegNo}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block">Aircraft Registered Country:</label>
                            <input
                                type="text"
                                className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                value={nationality}
                                disabled
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block">Aircraft Type:</label>
                            {loading ? (
                                <div>Loading...</div>
                            ) : (
                                <div>
                                    <select
                                        className="border border-gray-300 px-3 py-2 rounded-md w-full text-black"
                                        value={selectedType}
                                        onChange={(e) => setSelectedType(e.target.value)}
                                    >
                                        <option value="">Select a type</option>
                                        {aircraftTypes.map((type) => (
                                            <option key={type.aircraft_type_id} value={type.aircraft_model}>
                                                {type.aircraft_model}
                                            </option>
                                        ))}

                                    </select>

                                </div>
                            )}
                            {errors.selectedType && <p className="text-red-500 text-sm">{errors.selectedType}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block">Aircraft MTOW (Kg):</label>
                            <input
                                type="number"
                                className="border border-gray-300 px-3 py-2 rounded-md w-full text-black"
                                value={aircraftMTOW}
                                onChange={(e) => setAircraftMTOW(e.target.value)}
                            />
                            {errors.aircraftMTOW && <p className="text-red-500 text-sm">{errors.aircraftMTOW}</p>}
                        </div>
                        {/*<div className="mb-4">*/}
                        {/*    <label className="block">Aircraft capable of air dropping:</label>*/}
                        {/*    <select*/}
                        {/*        className="border border-gray-300 px-3 py-2 rounded-md w-full text-black"*/}
                        {/*        value={airdropCapability}*/}
                        {/*        onChange={(e) => setAirdropCapability(e.target.value)}*/}
                        {/*    >*/}
                        {/*        <option value="">Select Capability</option>*/}
                        {/*        <option value="YES">Yes</option>*/}
                        {/*        <option value="NO">No</option>*/}
                        {/*    </select>*/}
                        {/*    {errors.airdropCapability &&*/}
                        {/*        <p className="text-red-500 text-sm">{errors.airdropCapability}</p>}*/}
                        {/*</div>*/}
                        <div className="mb-4">
                            <label className="block">Total seating capacity:</label>
                            <input
                                type="number"
                                className="border border-gray-300 px-3 py-2 rounded-md w-3/12 text-black"
                                value={seatingCapacity}
                                onChange={(e) => setSeatingCapacity(e.target.value)}
                            />
                            {errors.seatingCapacity && <p className="text-red-500 text-sm">{errors.seatingCapacity}</p>}
                        </div>
                        {/*<div className="mb-4">*/}
                        {/*    <label className="block">Are there any communication systems on board?:</label>*/}
                        {/*    <select*/}
                        {/*        className="border border-gray-300 px-3 py-2 rounded-md w-full text-black"*/}
                        {/*        value={onboardCommunication}*/}
                        {/*        onChange={(e) => setOnboardCommunication(e.target.value)}*/}
                        {/*    >*/}
                        {/*        <option value="">Select an option</option>*/}
                        {/*        <option value="Yes">Yes</option>*/}
                        {/*        <option value="No">No</option>*/}
                        {/*    </select>*/}
                        {/*    {errors.onboardCommunication &&*/}
                        {/*        <p className="text-red-500 text-sm">{errors.onboardCommunication}</p>}*/}
                        {/*</div>*/}
                        <div className="mb-4">
                            <label className="block">Noise certificate:</label>
                            <input
                                type="file"
                                className="border border-gray-300 px-3 py-2 rounded-md w-full text-white"
                                onChange={(e) => setNoiseCertificate(e.target.files[0])}
                            />

                        </div>

                        <div className="mb-4">
                            <label className="block">Aircraft Operator Certificate:</label>
                            <input
                                type="file"
                                className="border border-gray-300 px-3 py-2 rounded-md w-full text-white"
                                onChange={(e) => setAOCCertificate(e.target.files[0])}
                            />

                        </div>

                    </div>
                    <div className="flex justify-between mt-4 mx-auto">
                        <button
                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            type="button"
                            onClick={goBack}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                            {BACK}
                        </button>

                        {submitLoading ? (
                            <button
                                type="button"
                                className="text-fts-blue rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            >
                                <CircularProgress size={20}/>
                            </button>
                        ) : (
                            <button
                                type="submit"
                                className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                            >
                                Next
                                <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                            </button>
                        )}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AircraftDetails;
