// utils/storage.js
export const getStorageItem = (key) => {
    try {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    } catch (error) {
        console.error(`Error getting item from localStorage - ${key}: `, error);
        return null;
    }
};

export const setStorageItem = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
        console.log(`Updated localStorage for key - ${key}: `, value);
    } catch (error) {
        console.error(`Error setting item in localStorage - ${key}: `, error);
    }
};

export const clearStorageItem = (key) => {
    try {
        localStorage.removeItem(key);
        console.log(`Cleared localStorage for key - ${key}`);
    } catch (error) {
        console.error(`Error clearing item from localStorage - ${key}: `, error);
    }
};

